.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
}

.homeHeader {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.homeDescription {
    font-size: 18px;
    color: #555;
    text-align: center;
}
.roundAnimation {
    animation: round 4s ease-in-out infinite;
}

@keyframes round {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.input-group-text {
    margin-top: 0;
    background-color: blue;
    padding: 30px;
}

.custom_button {
    margin-left: 1em;
    margin-right: 1em;
}



